import React from 'react';

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import config from 'config';
import useLogin from 'hooks/auth/useLogin';
import useLogout from 'hooks/auth/useLogout';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CustomTokenObtainPair } from 'types';
import * as authClient from 'utils/auth-client';
import notificationHandler from 'utils/notifications';

const AuthContext = React.createContext({});

function AuthProvider(props: any): any {
  const navigate = useNavigate();
  const token = React.useRef<string | null>(null);
  const queryClient = useQueryClient();
  const { errorNotification } = notificationHandler();

  const loginMutation = useLogin();
  const logoutMutation = useLogout();

  const refreshQuery = useQuery('refresh-token', authClient.refreshToken, {
    onSuccess: (data: any) => {
      token.current = data.data.access;
    },
    onError: () => {
      if (token.current) errorNotification('Error');
      token.current = null;
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
    refetchInterval: 1000 * 60 * 55,
  });

  const userQuery = useQuery(['me'], () => authClient.loadMe(token.current), {
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 56,
    enabled: !!token.current,
  });

  const login = ({
    email,
    password,
    rememberMe,
    navigateAfterLogin = true,
  }: CustomTokenObtainPair & { navigateAfterLogin: boolean }): void => {
    loginMutation.mutate(
      { email, password, rememberMe },
      {
        onSuccess: (data: any) => {
          token.current = data.data.access;
          if (navigateAfterLogin) {
            navigate('/', { replace: true });
          }
        },
        onError: error => {
          errorNotification('Login failed', error.response?.data.message);
        },
      },
    );
  };

  const logout = (): void => {
    logoutMutation.mutate(token.current, {
      onSuccess: () => {
        queryClient.clear();
        token.current = null;
        window.location.reload();
      },
      onError: () => {
        errorNotification('Logout failed');
      },
    });
  };

  const refresh = React.useCallback((): any => {
    return authClient
      .refreshToken()
      .then((data: any) => {
        token.current = data.data.access;
      })
      .catch((err: any) => {
        token.current = null;
        return Promise.reject(err);
      });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: userQuery.isSuccess && !!token.current,
        isLoading: refreshQuery.isLoading || userQuery.isLoading,
        loginIsLoading: loginMutation.isLoading,
        token,
        user: userQuery.data?.data,
        login,
        logout,
        refresh,
      }}
      {...props}
    />
  );
}

function useAuth(): any {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useClient(): AxiosInstance {
  const { token, refresh } = useAuth();
  const isRefreshing = React.useRef(false);

  return React.useCallback(() => {
    const cl = axios.create();
    cl.interceptors.request.use((axiosConfig: AxiosRequestConfig): AxiosRequestConfig => {
      return {
        ...axiosConfig,
        baseURL: config.API_SERVER,
        withCredentials: true,
        headers: {
          Authorization: token.current ? `JWT ${token.current}` : null,
        },
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
      };
    });

    cl.interceptors.response.use(
      response => response,
      async error => {
        const conf = error.config;
        if (error.response.status === 401 && !isRefreshing.current) {
          isRefreshing.current = true;
          return refresh()
            .then(() => {
              return cl.request(conf);
            })
            .catch((err: any) => {
              window.location.reload();
              return Promise.reject(err);
            });
        } else {
          return Promise.reject(error);
        }
      },
    );
    return cl;
  }, [token, refresh])();
}

export { AuthProvider, useAuth, useClient };
