import axios, { AxiosResponse } from 'axios';
import config from 'config';
import { useAuth } from 'context/auth-context';
import notificationHandler from 'utils/notifications';

interface ImpersonationResponse {
  accessToken: string;
  impersonatorToken: string;
}

const useImpersonation = (): any => {
  const { token } = useAuth();
  const axiosInstance = axios.create({
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
  });
  const { errorNotification } = notificationHandler();
  const impersonateUser = async (
    userId: string,
  ): Promise<AxiosResponse<{ [key: string]: string }> | undefined | void> => {
    try {
      axiosInstance.defaults.headers.common.Authorization = `JWT ${token.current}`;
      return await axiosInstance
        .post<ImpersonationResponse>(`${config.AUTHENTICATION_API}/impersonate/${userId}/`, {
          headers: {
            Authorization: `JWT ${token.current}`,
          },
        })
        .then(() => {
          window.location.href = '/';
        });
    } catch (err) {
      errorNotification('User impersonation failed.');
    }
  };

  const getImpersonatorToken = (): string | undefined => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    const impersonatorToken = cookies.find(cookie => cookie.startsWith('impersonator_token'));
    if (impersonatorToken !== undefined) {
      return impersonatorToken.split('=')[1];
    }
    return undefined;
  };

  const isLinkedAccountImpersonation = (): boolean | undefined => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    const linkedAccToken = cookies.find(cookie => cookie.startsWith('linked_acc_impersonation'));
    if (linkedAccToken !== undefined) {
      return linkedAccToken.split('=')[1] === 'True';
    }
  };

  const stopImpersonating = async (): Promise<undefined> => {
    try {
      axiosInstance.defaults.headers.common.Authorization = `JWT ${token.current}`;
      await axiosInstance
        .post(`${config.AUTHENTICATION_API}/stop-impersonation/`, {
          headers: {
            Authorization: `JWT ${token.current}`,
          },
        })
        .then(() => {
          window.location.href = '/gyms';
        });
    } catch (err) {
      errorNotification('User impersonation failed.');
    }
  };

  return { impersonateUser, stopImpersonating, getImpersonatorToken, isLinkedAccountImpersonation };
};

export default useImpersonation;
