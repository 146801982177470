import { MantineThemeOverride, Tuple, DefaultMantineColor } from '@mantine/core';

type ExtendColors = 'kiloBlue' | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendColors, Tuple<string, 10>>;
  }
}

const kiloBlue: Tuple<string, 10> = [
  '#FCFEFF',
  '#BFECFF',
  '#97DFFF',
  '#72D4FF',
  '#50CAFF',
  '#32C0FF',
  '#16B8FF',
  '#00AFFC',
  '#009EE3',
  '#008ECC',
];

// https://github.com/mantinedev/mantine/blob/master/src/mantine-styles/src/theme/default-theme.ts
export const lightTheme: MantineThemeOverride = {
  colorScheme: 'light',
  defaultRadius: 'md',
  colors: {
    kiloBlue,
  },
  primaryColor: 'kiloBlue',
  fontFamily: 'Inter, sans-serif',

  breakpoints: {
    xs: '26em',
    sm: '40em',
    md: '62em',
    lg: '75em',
    xl: '88em',
  },

  other: {
    fontWeights: {
      lightBold: 410,
      semibold: 500,
    },
  },

  components: {
    Drawer: {
      defaultProps: {
        keepMounted: false,
      },
    },

    InputWrapper: {
      styles: theme => ({
        label: {
          color: theme.colors.gray[6],
          fontStyle: 'italic',
        },
      }),
    },

    Checkbox: {
      styles: theme => ({
        label: {
          color: theme.colors.gray[6],
          fontStyle: 'italic',
        },
      }),
    },

    DateInput: {
      defaultProps: {
        weekendDays: [],
      },
    },

    DatePickerInput: {
      defaultProps: {
        weekendDays: [],
      },
    },

    DatePicker: {
      defaultProps: {
        weekendDays: [],
      },
    },

    Tabs: {
      defaultProps: {
        variant: 'outline',
        radius: 'md',
      },
      styles: theme => ({
        tab: {
          '&[data-active]': {
            backgroundColor: theme.white,
          },
        },
        tabsList: {
          background: theme.colors.gray[0],
        },
      }),
    },
  },
};

export const darkTheme: MantineThemeOverride = {
  ...lightTheme,
  colorScheme: 'dark',
};
